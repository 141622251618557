import logo from "./CSS/img/logo_fondo_rojo_100.jpg";
import imagen02 from "./CSS/img/imagen_2_100.jpg";
import imagen03 from "./CSS/img/imagen_3_100.jpg";
import imagen04 from "./CSS/img/imagen_4_100.jpg";
import imagen05 from "./CSS/img/imagen_5_100.jpg";
import imagen06 from "./CSS/img/bpw.ico";
import imagen07 from "./CSS/img/logotipo.jpg";
import ".//CSS/ppal.css";

function App() {
	return (
		<div className="App">
			<header>
				<div className="" id="fixed-bar">
					<nav className="navegacion">
						<ul className="menu1">
							<li className="li1">
								<img src={logo} height="100" alt="Logotipo" />
							</li>
							<li className="li2">
								<a href="#inicio" id="demo1" onclick="myFunction1()">
									Inicio
								</a>
							</li>
							<li className="li3">
								<a href="#principio2" id="demo2" onclick="myFunction2()">
									Quienes somos
								</a>
							</li>
							<li className="li4">
								<a href="#principio3" id="demo3" onclick="myFunction3()">
									Servicios
								</a>
							</li>
							<li className="li5">
								<a href="#principio4" id="demo4" onclick="myFunction4()">
									Clientes
								</a>
							</li>
							<li className="li6">
								<a href="#principio5" id="demo5" onclick="myFunction5()">
									Productos
								</a>
							</li>
							<li className="li6">
								<a href="#principio6" id="demo6" onclick="myFunction6()">
									Contacto
								</a>
							</li>
							{/* <script>
            function myFunction1() {
              document.getElementById("demo1").style.color = "red";
              document.getElementById("demo2").style.color = "#ffff";
              document.getElementById("demo3").style.color = "#ffff";
              document.getElementById("demo4").style.color = "#ffff";
              document.getElementById("demo5").style.color = "#ffff";
              document.getElementById("demo6").style.color = "#ffff";
            }
            function myFunction2() {
              document.getElementById("demo2").style.color = "red";
              document.getElementById("demo1").style.color = "#ffff";
              document.getElementById("demo3").style.color = "#ffff";
              document.getElementById("demo4").style.color = "#ffff";
              document.getElementById("demo5").style.color = "#ffff";
              document.getElementById("demo6").style.color = "#ffff";
            }
            function myFunction3() {
              document.getElementById("demo3").style.color = "red";
              document.getElementById("demo1").style.color = "#ffff";
              document.getElementById("demo2").style.color = "#ffff";
              document.getElementById("demo4").style.color = "#ffff";
              document.getElementById("demo5").style.color = "#ffff";
              document.getElementById("demo6").style.color = "#ffff";
            }
            function myFunction4() {
              document.getElementById("demo4").style.color = "red";
              document.getElementById("demo1").style.color = "#ffff";
              document.getElementById("demo3").style.color = "#ffff";
              document.getElementById("demo2").style.color = "#ffff";
              document.getElementById("demo5").style.color = "#ffff";
              document.getElementById("demo6").style.color = "#ffff";
            }
            function myFunction5() {
              document.getElementById("demo5").style.color = "red";
              document.getElementById("demo1").style.color = "#ffff";
              document.getElementById("demo3").style.color = "#ffff";
              document.getElementById("demo2").style.color = "#ffff";
              document.getElementById("demo4").style.color = "#ffff";
              document.getElementById("demo6").style.color = "#ffff";
            }
            function myFunction6() {
              document.getElementById("demo6").style.color = "red";
              document.getElementById("demo1").style.color = "#ffff";
              document.getElementById("demo3").style.color = "#ffff";
              document.getElementById("demo2").style.color = "#ffff";
              document.getElementById("demo5").style.color = "#ffff";
              document.getElementById("demo4").style.color = "#ffff";
            }
            </script> */}
							<li></li>
						</ul>
					</nav>
				</div>
			</header>
			<div className="cuerpo">
				<div className="seccion_1">
					<a name="inicio"></a>
					<div className="s1p1"></div>
					<div className="s1p2">
						<p>Somos los Creadores de BPW</p>
						<p>Best Practices Working</p>
						<a name="principio2"></a>
					</div>
				</div>

				<div className="seccion_espacio"></div>

				<div className="seccion_2">
					<div className="s_2_2">
						<div className="s2p1">
							<p>CÓDIGO_M</p>
						</div>
						<div className="s2p2">
							<div className="s2p2_1"></div>
							<div className="s2p2_2"></div>
						</div>
					</div>

					<p>
						Somos una empresa 100% Colombiana, fruto del emprendimiento que nace
						para satisfacer las necesidades cotidianas del sector Productivo
						proporcionando soluciones tecnológicas a las situaciones que
						enfrentan diferentes industrias. Nos transformamos en un proveedor
						global de este tipo de soluciones para cualquer sector que desee
						implementar teccnología informática en sus procesos productivos,
						comerciales y administrativos.
					</p>
				</div>

				<div className="seccion_2_2">
					<div className="s2p11">
						<p>
							Nuestro propósito es satisfacer todas aquellas necesidades
							tecnológicas que se presentan en procesos productivos, comerciales
							y/o administrativos en cualquier Organización mediante el
							suministro de servicios de software y/o asesoria en
							administración.
						</p>
						<p>
							Nuestro reto al 2025: ser la empresa de suministro de soluciones
							tecnológicas con un volúmen de clientes que permita una
							sostenibilidad importante a partir de una satisfacción total de
							los clientes y accionistas.
						</p>
					</div>
					<div className="s2p12">
						<img src={imagen02} alt="" />
					</div>
				</div>

				<div className="seccion_espacio">
					<a name="principio3"></a>
				</div>
				<div className="seccion_espacio"></div>

				<div className="seccion_3">
					<div className="s3p2">
						<a name="principio2"></a>
						<p>SERVICIOS</p>
					</div>
					<div className="s3p1">
						<img src={imagen03} alt="" />
					</div>
				</div>

				<div className="seccion_espacio"></div>

				<div className="seccion_4">
					<div className="s4p1">
						<p>PROGRAMACIÓN</p>
						<img src={imagen04} alt="" />
						<p className="parrafito">
							Realizamos aplicaciones web y App en diferentes lenguajes de
							programación de acuerdo a los requerimientos del cliente.
						</p>
					</div>
					<div className="s4p2">
						<p>DISEÑO</p>
						<img src={imagen05} alt="" />

						<p className="parrafito">
							Suplimos las necesidades con soluciones informáticas diseñadas a
							la medida de cada operación.
						</p>
					</div>
				</div>

				<div className="seccion_espacio">
					<a name="principio4"></a>
				</div>

				<div className="seccion_5">
					<div className="s5p1">
						<div className="s5p11">
							<p>CLIENTES</p>
						</div>
						<div className="s5p12">
							<div className="s5p12_1"></div>
							<div className="s5p12_2"></div>
						</div>
					</div>
					<div className="s5p2">
						<img
							src="{{url_for('static',filename='CSS/img/kia.png')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/logotipo.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/autoorion.png')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/distrikia.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/fujiyama.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/kiaplaza.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/carrazos.png')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/laflorida.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/motorkia.jpeg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/motorysa.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/mundokia.png')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/spv.jpg')}}"
							height="100"
							alt="Logotipo"
						/>
						<img
							src="{{url_for('static',filename='CSS/img/volkswagen.png')}}"
							height="100"
							alt="Logotipo"
						/>
					</div>
				</div>
				<a name="principio5"></a>
				<div className="seccion_espacio"></div>
				<div className="seccion_espacio"></div>

				<div className="productos">
					<p>PRODUCTOS</p>
				</div>

				<div className="seccion_6">
					<div className="s6p1">
						<p className="parrafito">
							Somos los creadores de BPW. Esta es una solución para empresas de
							prodducción, que permite hacer trazabilidad de todos sus
							procedimientos, almacenar documentos, atender de manera efectiva
							cualquier auditoría de calidad.
						</p>
						<a href="http://www.bpw.com.co">bpw.com.co</a>
					</div>
					<div className="s6p2">
						<img src={imagen06} alt="" />
						{/* <img
							src="{{url_for('static',filename='CSS/img/logo_ivvuo.png')}}"
							alt=""
						/> */}
					</div>
				</div>
				<div className="seccion_6">
					<div className="s6p2">
						<img src={imagen07} alt="" />
					</div>
					<div className="s6p1">
						<p className="parrafito">
							Hemos diseñado una Aplicación para hacer comunidad entre los
							Jugadores de Golf. TEE-SHOT es el sitio donde los juadores y
							amantes del GOLF podrán encontrar todas las soluciones a sus
							necesidades, de tal manera que en un solo sitio, todas sus
							expectativas se puedan resolver mediante la web. Se pueden seguir
							juegos de Golf, crear competencias, agendar juegos y realizar
							tranascciones comerciales de compra y venta de todo tipo de
							artículos de golf.
						</p>
						<a href="http://www.teeshot.com.co">teeshot.com.co</a>
					</div>
				</div>

				<a name="principio6"></a>
				{/* <div className="seccion_espacio">
  </div>  */}

				<div className="seccion_7">
					<div class="s7p1">
						<p>e-mail: john.mejia@codigom.com.co</p>
						<a href="http://www.codigom.com.co">
							<p>www.codigom.com.co</p>
						</a>
						<a name="principio2"></a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
